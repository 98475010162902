import { inject, injectable } from 'inversify';
import Request from '@/Framework/api/Rpc/Request';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import RpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';

@injectable()
class BackendRedirectRepository {
  constructor(@inject(RpcDispatcher) protected readonly rpc: typeof RpcDispatcher) {}

  getNewRoute = async (url: string): Promise<{ url: string, code: string }> => {
    const request = new Request('finsight_web.seo.get_redirect', { url });
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };
}

export default BackendRedirectRepository;
